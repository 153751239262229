import "./LoyaltyProgram.scss"

import { Button, Icon, InputPreview, PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import featureFlags from "../../../constants/feature-flags"
import { HOME, PENGUIN_PERKS } from "../../../constants/routes"
import loyaltyActions from "../../../redux/loyalty/actions"

const LoyaltyProgram = ({ balance, loyaltyNumber, tier, trips }) => {
  const dispatch = useDispatch()
  const activateLoyalty = useCallback(
    isActive => dispatch(loyaltyActions.activateLoyalty(isActive)),
    [dispatch],
  )

  const history = useHistory()

  if (!featureFlags().loyaltyFeatureFlag) return null

  const goToBookNow = () => {
    history.push(HOME)
  }

  const goToPerksPage = () => {
    history.push(PENGUIN_PERKS)
  }

  return (
    <>
    <div className="loyalty-program-wrapper">
      <PageTitle
        title="Penguin Perks"
        fontSize="20px"
        margin="0 0 20px 0"
        style={{
          height: 40,
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
        }}
      />
      <InputPreview
        title={<span>CURRENT TIER</span>}
        value={tier.toUpperCase()}
      />
      <InputPreview title="TRIPS ACCRUED THIS YEAR" value={trips} />
      <div className="loyalty-program-buttons">
        <Button
          type="secondary"
          onClick={goToBookNow}
          fullWidth
          icon={<Icon align="right" name="points" />}
        >
          Book Now
        </Button>
      </div>
    </div>
    {/* MileMarkers Program (DISCONTINUED) */}
    <div className="loyalty-program-wrapper">
      <PageTitle
        title="MileMarkers&#8480; Program"
        fontSize="20px"
        margin="0 0 20px 0"
        style={{
          height: 40,
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
        }}
      />
      <p className="miles-warning">The Landline MileMarkers Program has been transformed into the new and improved Penguin Perks Program designed to offer our loyal riders even more perks and rewards. Don’t worry your accrued miles will not disappear immediately!  Passengers will have 1 year to use and redeem their miles before they expire. All unused miles will expire 10/01/2025.</p>
      <InputPreview
        title={<span>YOUR MILEMARKERS&#8480; NUMBER</span>}
        value={`#${loyaltyNumber}`}
      />
      <InputPreview title="YOUR MILES" value={balance} />
      <div className="loyalty-program-buttons">
        <Button
          type="secondary"
          onClick={goToPerksPage}
          fullWidth
          icon={<Icon align="right" name="points" />}
        >
          Learn More About Penguin Perks
        </Button>
      </div>
    </div>
    </>
  )
}

LoyaltyProgram.propTypes = {
  balance: PropTypes.number.isRequired,
  loyaltyNumber: PropTypes.string.isRequired,
}

LoyaltyProgram.defaultProps = {}

export default LoyaltyProgram
