const PenguinPerksMeta = {
    header: {
        title: "Penguin Perks",
        description: "Welcome to the new Landline Loyalty Program, where every journey counts towards exciting rewards and exclusive perks.\nEarn Penguin Perks with every ride on Landline, our premium shuttle services designed for your comfort and convenience. \nWhether you're travelling for work or pleasure, each trip brings you closer to unlocking incredible benefits."
    }
}

const HowItWorks = {
    headline: "How It Works",
    elements: [
        {
            title: "Earn FREE Rides",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Bus-Icon.png",
            description: "Every Landline ride gets you closer to your next free ride. Trips accumulate in your loyalty account automatically."
        },
        {
            title: "Level Up",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Level-Up-Icon.png",
            description: "There are three prestigious tiers: Silver, Gold & Platinum. Your status is determined by the number of rides you take within a year."
        },
        {
            title: "Redeem Rewards",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Gift-Icon.png",
            description: "As you ascend through the tiers enjoy increasingly rewarding Penguin Perks and gifts!"
        }
    ]
}

const RiderBenefits = {
    discount: "10% discount on every trip",
    free_trip_silver: "1 FREE one-way trip to use per calendar year",
    free_trip_gold_plat: "2 FREE one-way trip to use per calendar year",
    refundable: "Refundability on cancelled legs",
    free_ride_silver: "Earn 1 FREE ride for every 10 trips you take with Landline",
    free_ride_gold: "Earn 1 FREE ride for every 9 trips you take with Landline",
    free_ride_plat: "Earn 1 FREE ride for every 8 trips you take with Landline",
    boarding: "Priority boarding at FNL",
    partner_pass: "Partner Pass"
}

const RiderTiers = {
    headline: "Rider Tiers",
    tiers: [
        {
            name: "Silver",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Silver.png",
            threshold: "Earn after 5 trips in a 12 month period",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_trip_silver,
                RiderBenefits.refundable,
                RiderBenefits.free_ride_silver
            ]
        },
        {
            name: "Gold",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Gold.png",
            threshold: "Earn after 10 trips in a 12 month period",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_trip_gold_plat,
                RiderBenefits.refundable,
                RiderBenefits.free_ride_gold
            ]
        },
        {
            name: "Platinum",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Platinum.png",
            threshold: "Earn after 15 trips in a 12 month period",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_trip_gold_plat,
                RiderBenefits.partner_pass,
                RiderBenefits.boarding,
                RiderBenefits.refundable,
                RiderBenefits.free_ride_plat
            ]
        }
    ]
}

const FAQ = [
    {
        q: "What happened to Mile Markers?",
        a: "Penguin Perks is our newly designed loyalty program. Mile Markers has been discontinued, but don’t worry you can still redeem your accrued miles from the old system for up to....."
    },
    {
        q: "How do I level up to the next tier?",
        a: "Simply ride Landline more! If you ride Landline 5 times within a 12 month period, you will earn Silver status, ride us 10 times (that is only 5 round trips a year) earn Gold status, and ride 15 or more times a year to reach Platinum tier."
    },
    {
        q: "Once I reach a certain tier, do I maintain that status for life?",
        a: "Once you reach a tier, you will receive those benefits for the entire year after, but you have to keep riding to maintain your status for the year(s) after that."
    },
    {
        q: "How do I redeem the free rides?",
        a: "You will earn points every time you ride with Landline, which can be viewed on your Penguin Perk’s profile page....."
    },
    {
        q: "What is a partner pass?",
        a: "For our Platinum tier riders, you will get the benefit of always being allowed to have a companion ride with you for free! Buy your ticket at your discounted rate, and add a second adult passenger for FREE every time you ride! Don’t forget kids 12 and under are always free on Landline."
    }
]

export {
    PenguinPerksMeta,
    HowItWorks,
    RiderTiers,
    FAQ
}